import React, { useState } from 'react';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import './App.css';

const languageOptions = [
  { value: 'en', label: '英语' },
  { value: 'zh', label: '中文' },
  // 可以添加更多选项
];

function App() {
  const [sourceLang, setSourceLang] = useState(null);
  const [targetLang, setTargetLang] = useState(null);
  const [country, setCountry] = useState('');
  const [inputText, setInputText] = useState('');
  const [translation, setTranslation] = useState('');

  const handleTranslate = async () => {
    try {
      // 修改API请求地址
      const response = await axios.post('http://154.44.8.130:5000/translate', {
        source_lang: sourceLang ? sourceLang.value : '',
        target_lang: targetLang ? targetLang.value : '',
        country: country,
        input_text: inputText
      });

      console.log(response.data);

      if (response.data && response.data.translation) {
        setTranslation(response.data.translation);
      } else {
        setTranslation('Error: Invalid response from server');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setTranslation('Error: ' + error.response.data.error);
      } else {
        setTranslation('Error: ' + error.message);
      }
    }
  };

  return (
    <div className="App">
      <div className="header">
        <h1>反思翻译</h1>
      </div>
      <div className="translation-container">
        <div className="input-section">
          <div className="input-header">
            <CreatableSelect
              value={sourceLang}
              onChange={setSourceLang}
              options={languageOptions}
              placeholder="选择或输入源语言"
            />
          </div>
          <textarea
            placeholder="输入以翻译"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </div>
        <div className="output-section">
          <div className="output-header">
            <CreatableSelect
              value={targetLang}
              onChange={setTargetLang}
              options={languageOptions}
              placeholder="选择或输入目标语言"
            />
            <input
              type="text"
              placeholder="国家"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <textarea
            readOnly
            placeholder="翻译结果"
            value={translation}
          />
        </div>
      </div>
      <button onClick={handleTranslate}>提交</button>
    </div>
  );
}

export default App;
