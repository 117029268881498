// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.translation-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.input-section, .output-section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.input-header, .output-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

select, .react-select__control {
  padding: 10px;
  font-size: 16px;
}

textarea {
  flex-grow: 1;
  height: 500px; /* 将高度设置为500px */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

button:hover {
  background-color: #0056b3;
}

.output-header input {
  margin-left: 10px;
  padding: 10px;
  font-size: 16px;
  width: 100px; /* 设置国家输入框的宽度为100px */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa,EAAE,gBAAgB;EAC/B,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,YAAY,EAAE,qBAAqB;AACrC","sourcesContent":[".App {\n  font-family: Arial, sans-serif;\n  margin: 20px;\n}\n\n.header {\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.translation-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.input-section, .output-section {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n\n.input-header, .output-header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\nselect, .react-select__control {\n  padding: 10px;\n  font-size: 16px;\n}\n\ntextarea {\n  flex-grow: 1;\n  height: 500px; /* 将高度设置为500px */\n  padding: 10px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  resize: none;\n}\n\nbutton {\n  margin-top: 20px;\n  padding: 10px 20px;\n  font-size: 16px;\n  background-color: #007BFF;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\n.output-header input {\n  margin-left: 10px;\n  padding: 10px;\n  font-size: 16px;\n  width: 100px; /* 设置国家输入框的宽度为100px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
